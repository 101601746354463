@import "./mixins";
@import "./variables";
@import "./extensions";

.footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: black;
    opacity: 80%;
    font-size: 11px;
    color: white;
    z-index: 200;
    display: flex;
    justify-content: center;
    text-align: center;
}
.footer a {
    color: white;
    text-decoration: none;
}

.footer a:hover {
    color: white;
    text-decoration: underline;
}
.helpIcon {
    position: fixed;
    bottom: 25px; 
    right: 20px;  
    z-index: 200;
    display: flex;
    justify-content: center;
    align-items: center; 
    text-align: center; 
    width: 40px; 
    height: 40px; 
    color: #FFF;
    background-color: #EB0000;
    border-radius: 50%; 
    line-height: 1;
}
